import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./css/style.css";
import Store from "./redux/store/Store";
import reportWebVitals from "./reportWebVitals";
import Routing from "./routing";
ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Routing />
    </Provider>{" "}
  </React.StrictMode>,
  document.getElementById("root"),
  document.getElementById("root").classList.add("container")
);
reportWebVitals();
