export const LINKS_SOCIAL_MEDIA = {
  FACEBOOK: "https://www.facebook.com/ben.franklin.712161/",
  INSTAGRAM: "https://www.instagram.com/badass._.ben/",
  LINKEDIN: "https://www.linkedin.com/in/b0f/",
  STACKOVERFLOW: "https://stackoverflow.com/users/10428984/ben-franklin",
  GITHUB: "https://github.com/Ben-Franklin-k",
};

export const ROUTES = {
  HOME: "/home",
  POSTS: "/posts",
  PROJECT: "/project",
  CONTACT_ME: "/contact-me",
  GALLERY: "/gallery",
  ABOUT_ME: "/about-me",
};
export const EXPERIENCE = {
  HID: "https://www.hidglobal.com/",
  RMK: "http://www.rmkec.ac.in/",
  SRM: "https://www.srmist.edu.in/",
  VELAMMAL: "http://www.velammal.org/",
};
export const HEADER_ITEMS = [
  { href: ROUTES.HOME, title: "Home" },
  { href: ROUTES.ABOUT_ME, title: "About Me" },
  { href: ROUTES.GALLERY, title: "Gallery" },
  { href: ROUTES.CONTACT_ME, title: "Contact Me" },
  { href: ROUTES.POSTS, title: "Posts" },
  { href: ROUTES.PROJECT, title: "Project" },
];
export const PHOTO_STUDIOS = {
  AV_STUDIOS: "https://www.instagram.com/av_studioss/",
  RK_STUDIOS: "https://www.instagram.com/_rk_studios/",
  SASI_STUDIOS: "https://www.instagram.com/iam__sasidaran/",
  SAROWAR_PHOTOGRAPHY: "https://www.facebook.com/adnansarowar",
};

export const POSTS = {
  AWS_LINK:
    "https://benfranklin619.medium.com/follow-these-steps-to-clear-aws-certified-associate-developer-exam-98d76558ec1f",
  INTERVIEW_LINK:
    "https://benfranklin619.medium.com/interview-experience-e14a5b9d1c4d",
  SPRINGER_LINK: "https://www.springer.com/de/book/9789811501456",
};
