/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { createPortal } from "react-dom";
import { ReactComponent as Close } from "../../assets/svg/close.svg";

export default function Modal({ href, show, setshow, content }) {
  return createPortal(
    show && (
      <div className="modal ">
        <div className="modal__body show">
          <a
            onClick={() => setshow(false)}
            href={href}
            className="avatar dark  modal__close-btn"
          >
            <Close className="avatar__icon" />
          </a>
          {content}
        </div>
      </div>
    ),
    document.body
  );
}
