import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Modal from "./components/modal";
import { ROUTES } from "./constants";

const AboutMe = lazy(() => import("./pages/aboutMe"));

const ContactMe = lazy(() => import("./pages/contactMe"));
const Gallery = lazy(() => import("./pages/gallery"));
const Home = lazy(() => import("./pages/home"));
const Posts = lazy(() => import("./pages/posts"));
const Project = lazy(() => import("./pages/project"));
const Footer = lazy(() => import("./components/footer/Footer"));
const Header = lazy(() => import("./components/header/Header"));

const Routing = () => {
  return (
    <Router>
      <Suspense fallback={<h1>Loading profile...</h1>}>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path={ROUTES.HOME} component={Home} />
          <Route exact path={ROUTES.POSTS} component={Posts} />
          <Route exact path={ROUTES.CONTACT_ME} component={ContactMe} />
          <Route exact path={ROUTES.ABOUT_ME} component={AboutMe} />
          <Route exact path={ROUTES.GALLERY} component={Gallery} />
          <Route exact path={ROUTES.PROJECT} component={Project} />
          <Modal />
        </Switch>
        <Footer />
      </Suspense>
    </Router>
  );
};
export default Routing;
